import { createTheme, lighten } from "@mui/material";
import { shape } from "./shape";
import { typography } from "./typography";

export const baseMuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.default,
          border: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: ({ theme }) => ({
          padding: `0 ${theme.spacing(1)}`,
          margin: `${theme.spacing(3)} 0`,
          "&.Mui-expanded": {
            margin: `${theme.spacing(3)} 0`,
          },
        }),
        expandIconWrapper: ({ theme }) => ({
          background: theme.palette.background.paper,
          borderRadius: "50px",
          padding: theme.spacing(0.5),
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
          paddingTop: 0,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "700",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },

    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.75),
          backgroundColor: theme.palette.divider,
          borderRadius: `15px`,
        }),
        indicator: {
          display: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "capitalize",
          fontWeight: "bold",
          minHeight: "50px",
          borderRadius: `15px`,
          "&.Mui-selected": {
            backgroundColor: theme.palette.background.default,
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          verticalAlign: "top",

          ":not(:last-child)": {
            borderRight: `1px solid ${theme.palette.divider}`,
          },
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "15px",
          ":nth-of-type(even)": {
            backgroundColor: theme.palette.background.paper,
          },
          "& .MuiTableCell-root:first-child": {
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          },
          "& .MuiTableCell-root:last-child": {
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: "transparent",
          borderRadius: `calc(${theme.shape.borderRadius}px - ${theme.spacing(
            1
          )})`,
        }),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 700,
          textTransform: "capitalize",
          borderRadius: "50px",
        }),
        outlined: ({ ownerState }) => ({
          borderWidth: "2px",
          "&:hover": {
            borderWidth: "2px",
          },
        }),
        sizeLarge: ({ theme }) => ({
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        }),
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "40px",
          minWidth: "40px",
          borderRadius: `${theme.shape.borderRadius}px`,
          fontSize: "14px",
        }),
        outlined: ({ theme }) => ({
          background: theme.palette.secondary.main,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
        },
        h3: {
          fontWeight: 700,
        },
        h4: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 700,
        },
        h6: {
          fontWeight: 700,
        },
        body1: {
          lineHeight: "24px",
        },
        root: {
          fontWeight: 400,
        },
      },
    },
  },
  shape,
  typography,
});
