import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Page } from "../Page/Page";
import SupportIcon from "@mui/icons-material/Support";
import { NavLink } from "react-router-dom";
import {
  getCareersRoute,
  getFAQRoute,
  getHelpCenterRoute,
  getHowItWorksRoute,
  getIntellectualPropertyClaimsRoute,
  getPrivacyPolicyRoute,
  getSafetyRoute,
  getServiceOffersRoute,
  getTermsOfServiceRoute,
} from "../../routes";
import visaImg from "../../resources/payments/visa-white.svg";
import mastercardImg from "../../resources/payments/mc-symbol.svg";
import logo3dsImg from "../../resources/payments/3ds.svg";
import { NewsletterForm } from "../../forms/NewsletterForm/NewsletterForm";
import { useSnackbar } from "notistack";
import { AppLogo } from "../AppLogo/AppLogo";
import { categories } from "../../models/categories";

export const Footer = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Paper
      sx={{ borderTop: `1px solid ${theme.palette.divider}`, borderRadius: 0 }}
    >
      <Page sx={{ pt: 8, pb: 4 }}>
        <Grid container sx={{ mb: 8 }}>
          <Grid item xs={12} md={6}>
            <AppLogo
              sx={{ mb: 4, height: "35px" }}
              color={theme.palette.text.primary}
            />
            <Typography fontSize="small" color="text.secondary" sx={{ mb: 8 }}>
              Derry Solution Sp.z.o.o
              <br />
              Hoza str. 86, apt.210, Warsaw, Poland
              <br />
              Reg № 525084542
              <br/>
              info@ro-dians.com
            </Typography>
            <Stack spacing={1}>
              <Typography fontWeight="700">Get news</Typography>
              <Box sx={{ maxWidth: "380px" }}>
                <NewsletterForm
                  onComplete={() => {
                    enqueueSnackbar(
                      "You have successfully subscribed to news!",
                      {
                        variant: "success",
                      }
                    );
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack spacing={1}>
              <Typography fontWeight="700" sx={{ mb: 3 }}>
                Categories
              </Typography>
              <NavLink
                to={getServiceOffersRoute.path}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  All categories
                </Typography>
              </NavLink>
              {categories.map((name) => (
                <NavLink
                  key={name}
                  to={`${
                    getServiceOffersRoute.path
                  }?categories=${encodeURIComponent(name)}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography fontSize="smaller" color="text.secondary">
                    {name}
                  </Typography>
                </NavLink>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack spacing={1}>
              <Typography fontWeight="700" sx={{ mb: 3 }}>
                Help center
              </Typography>
              <NavLink
                to={`${getHelpCenterRoute.path}/${getFAQRoute.path}`}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  FAQ
                </Typography>
              </NavLink>
              <NavLink
                to={`${getHelpCenterRoute.path}/${getPrivacyPolicyRoute.path}`}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  Rodian Privacy Policy
                </Typography>
              </NavLink>
              <NavLink
                to={`${getHelpCenterRoute.path}/${getTermsOfServiceRoute.path}`}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  Rodian Terms of Service
                </Typography>
              </NavLink>
              <NavLink
                to={`${getHelpCenterRoute.path}/${getIntellectualPropertyClaimsRoute.path}`}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  Intellectual Property Claims
                </Typography>
              </NavLink>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack spacing={1}>
              <Typography fontWeight="700" sx={{ mb: 3 }}>
                Other
              </Typography>
              <NavLink
                to={getCareersRoute.path}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  Careers
                </Typography>
              </NavLink>
              <NavLink
                to={getSafetyRoute.path}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  Trust safety
                </Typography>
              </NavLink>
              <NavLink
                to={getHowItWorksRoute.path}
                style={{ textDecoration: "none" }}
              >
                <Typography fontSize="smaller" color="text.secondary">
                  How it works
                </Typography>
              </NavLink>
            </Stack>
          </Grid>
        </Grid>
        <Paper sx={{ background: theme.palette.text.primary, px: 3, py: 1.5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="common.white" fontSize="smaller">
              Copyright © 2023 Rodian Technologies
            </Typography>
            <Stack direction="row" spacing={3}>
              <Box component="img" src={visaImg} sx={{ width: "35px" }} />
              <Box component="img" src={mastercardImg} sx={{ width: "35px" }} />
              <Box component="img" src={logo3dsImg} width="40px" />

              <NavLink
                to={getHelpCenterRoute.path}
                style={{ textDecoration: "none" }}
              >
                <Button
                  startIcon={<SupportIcon />}
                  sx={{ color: theme.palette.common.white, fontWeight: "400" }}
                >
                  Feedback
                </Button>
              </NavLink>
            </Stack>
          </Stack>
        </Paper>
      </Page>
    </Paper>
  );
};
