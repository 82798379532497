import { Page } from "../../components/Page/Page";
import React from "react";
import { CreateServiceOfferForm } from "../../forms/CreateAssetForm/CreateServiceOfferForm";
import { Link, useNavigate } from "react-router-dom";
import { getMainPageRoute, getProfileRoute } from "../../routes";
import { useSnackbar } from "notistack";
import {
  Breadcrumbs,
  Divider,
  Link as MUILink,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

export const CreateServiceOfferPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Breadcrumbs sx={{ mb: 4 }}>
        <MUILink
          component={Link}
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          to={getMainPageRoute.path}
        >
          <HomeIcon sx={{ fontSize: "16px", color: "text.secondary" }} />
        </MUILink>
        <MUILink
          component={Link}
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          to={getProfileRoute.path}
          underline="none"
        >
          Profile
        </MUILink>
        <Typography color="text.primary">Add a service</Typography>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Add a service
      </Typography>
      <CreateServiceOfferForm
        onComplete={() => {
          navigate(getProfileRoute.path);
          enqueueSnackbar("Project successfully added", {
            variant: "success",
          });
        }}
        onError={() => {
          enqueueSnackbar("Something went wrong. We will fix it soon", {
            variant: "error",
          });
        }}
      />
      <Divider sx={{ my: 5 }} />
    </Page>
  );
};
