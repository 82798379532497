import { Container, ContainerProps } from "@mui/material";
import { useThemeSettingsContext } from "../../hooks/useThemeSettingsContext";

export type Props = {
  children?: any;
  sx?: ContainerProps["sx"];
  maxWidth?: ContainerProps["maxWidth"];
};

export const Page: React.FC<Props> = ({
  children,
  sx = {},
  maxWidth = "lg",
}) => {
  return (
    <Container
      component="section"
      sx={{ ...sx, width: "100%" }}
      maxWidth={maxWidth}
    >
      {children}
    </Container>
  );
};
