import { ApolloClient, createHttpLink } from "@apollo/client";
import { cache } from "./cache";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://api.rodian.world/graphql",
  // uri: "http://localhost:5100/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth-token");
  const currency = localStorage.getItem("currency");
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: token?.slice(1, -1) } : {}),
      ...(currency ? { "x-currency": currency?.slice(1, -1) } : {}),
    },
  };
});

export const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});
