import design from "../resources/photos/designer-tablet-pen.jpg";
import marketing from "../resources/photos/man-coffee-tablet.jpg";
import writing from "../resources/photos/woman-black-writing.jpg";
import video from "../resources/photos/woman-video-editor.jpg";
import music from "../resources/photos/woman-video-editor.jpg";

export const categories = [
  "Graphics & Design",
  "Digital Marketing",
  "Writing & Translation",
  "Video & Animation",
  "Music & Audio",
] as const;

export const CATEGORY_TO_SHORT_CATEGORY_MAP: Record<
  typeof categories[number],
  string
> = {
  "Graphics & Design": "Design",
  "Digital Marketing": "ADS",
  "Writing & Translation": "Text",
  "Video & Animation": "Motion design",
  "Music & Audio": "Audio",
};

export const CATEGORY_TO_IMAGE_MAP: Record<typeof categories[number], string> =
  {
    "Graphics & Design": design,
    "Digital Marketing": marketing,
    "Writing & Translation": writing,
    "Video & Animation": video,
    "Music & Audio": music,
  };
