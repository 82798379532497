import { Box, StackProps, useTheme } from "@mui/material";
import React from "react";

type Props = {
  sx?: StackProps["sx"];
  color?: string;
};
export const AppLogo: React.FC<Props> = ({ sx, color }) => {
  const theme = useTheme();
  color = color || theme.palette.primary.dark;
  return (
    <Box
      sx={{
        height: "45px",
        [theme.breakpoints.down("sm")]: { height: "30px" },
        ...sx,
        alignItems: "center",
        display: "flex",
      }}
    >
      <svg
        height="100%"
        viewBox="0 0 120 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2632 0.0244141H0.447617C0.201633 0.0244141 0 0.227412 0 0.475077V19.5493C0 19.797 0.201633 20 0.447617 20H7.065C6.28672 16.35 6.51657 12.5944 7.8836 12.0057C9.69018 11.2262 14.1219 15.6801 15.9164 20H19.3884C19.6344 20 19.836 19.797 19.836 19.5493V3.62571C19.836 1.63627 18.2351 0.0244141 16.2592 0.0244141H16.2632ZM15.4809 7.13769C14.38 9.90666 7.50051 10.5076 6.80691 8.88758C6.10928 7.26761 11.2467 2.59444 14.013 3.72314C14.2631 3.82465 15.1986 4.20629 15.5736 5.11981C15.9325 5.99679 15.5817 6.87784 15.4809 7.13769Z"
          fill={color}
        />
        <path
          d="M38.2849 0.0244141H25.6025C23.6266 0.0244141 22.0257 1.63627 22.0257 3.62571V16.3947C22.0257 18.3841 23.6266 19.996 25.6025 19.996H38.2849C40.2608 19.996 41.8617 18.3841 41.8617 16.3947V3.62571C41.8617 1.63627 40.2608 0.0244141 38.2849 0.0244141ZM37.1074 11.0516H34.4338L36.3251 12.9558C36.7283 13.3618 36.7283 14.0195 36.3251 14.4255C35.9218 14.8315 35.2685 14.8315 34.8653 14.4255L32.974 12.5213V15.2132C32.974 15.7856 32.5103 16.2526 31.9417 16.2526C31.3731 16.2526 30.9094 15.7856 30.9094 15.2132V12.5213L29.0181 14.4255C28.6149 14.8315 27.9616 14.8315 27.5583 14.4255C27.1551 14.0195 27.1551 13.3618 27.5583 12.9558L29.4496 11.0516H26.776C26.2074 11.0516 25.7437 10.5847 25.7437 10.0122C25.7437 9.43974 26.2074 8.97283 26.776 8.97283H29.4496L27.5583 7.06865C27.1551 6.66264 27.1551 6.00491 27.5583 5.5989C27.9616 5.19289 28.6149 5.19289 29.0181 5.5989L30.9094 7.50308V4.81124C30.9094 4.23877 31.3731 3.77186 31.9417 3.77186C32.5103 3.77186 32.974 4.23877 32.974 4.81124V7.50308L34.8653 5.5989C35.2685 5.19289 35.9218 5.19289 36.3251 5.5989C36.7283 6.00491 36.7283 6.66264 36.3251 7.06865L34.4338 8.97283H37.1074C37.676 8.97283 38.1397 9.43974 38.1397 10.0122C38.1397 10.5847 37.676 11.0516 37.1074 11.0516Z"
          fill={color}
        />
        <path
          d="M56.7296 0.0243277H44.4949C44.2489 0.0243277 44.0473 0.227326 44.0473 0.474991V19.5493C44.0473 19.7969 44.2489 19.9999 44.4949 19.9999H56.7296C60.6815 19.9999 63.8874 16.7762 63.8874 12.7933V7.22691C63.8874 3.24803 60.6855 0.0202637 56.7296 0.0202637V0.0243277ZM57.7781 12.3913C55.7134 15.071 49.2856 12.2736 49.2856 10.0243C49.2856 7.77503 55.7175 4.92484 57.7781 7.65728C57.9636 7.90088 58.6572 8.82658 58.6451 10.0852C58.6289 11.291 57.9716 12.1477 57.7781 12.3954V12.3913Z"
          fill={color}
        />
        <path
          d="M90.4859 0H85.4049C81.4531 0 78.2472 3.22371 78.2472 7.20665V19.5249C78.2472 19.7726 78.4488 19.9756 78.6948 19.9756H84.0137C85.0985 17.8116 86.6954 16.1551 88.1471 16.1551C89.5988 16.1551 91.2078 17.8116 92.3046 19.9756H97.6356C97.8816 19.9756 98.0832 19.7726 98.0832 19.5249V7.65325C98.0832 3.4267 94.6798 0 90.4819 0H90.4859ZM90.5424 11.3723C90.2964 11.5266 89.3649 12.1072 88.0987 12.095C86.8849 12.0828 86.026 11.5306 85.7759 11.3723C83.0822 9.64676 85.8969 4.27122 88.1592 4.27122C90.4254 4.27122 93.2885 9.65082 90.5424 11.3723Z"
          fill={color}
        />
        <path
          d="M70.9644 4.64885C72.8153 4.67728 74.1703 3.43487 74.5292 3.106C75.3518 2.35489 75.8236 1.2668 76.0373 0.0244141H66.0972C66.3028 1.2668 66.7666 2.35083 67.5731 3.106C67.936 3.44705 69.1941 4.62449 70.9644 4.64885Z"
          fill={color}
        />
        <path
          d="M71.1701 6.57732C69.3191 6.54484 67.9642 7.99022 67.6053 8.37187C65.3834 10.7348 65.6818 15.879 66.944 19.9959H75.1986C76.4486 15.879 76.739 10.7389 74.5614 8.37187C74.1985 7.97398 72.9403 6.6098 71.1701 6.57732Z"
          fill={color}
        />
        <path
          d="M119.548 0H114.004C115.492 4.08038 115.645 9.15548 114.064 9.91065C111.975 10.9054 105.971 4.79902 104.975 0H100.603C100.358 0 100.156 0.202998 100.156 0.450663V19.5249C100.156 19.7726 100.358 19.9756 100.603 19.9756H106.229C105.37 16.4271 105.487 12.8014 106.806 12.1721C108.596 11.3195 113.266 15.6882 115.189 19.9756H119.552C119.798 19.9756 120 19.7726 120 19.5249V0.450663C120 0.202998 119.798 0 119.552 0H119.548Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};
