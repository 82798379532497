import { createContext } from "react";
import { useDarkMode, useLocalStorage } from "usehooks-ts";

export type ThemeSettingsContextInterface = {
  isDarkMode: boolean;
  isRTLEnabled: boolean;
  toggleDarkModeEnabled: () => void;
  toggleRTLEnabled: () => void;
  toggleFullScreenEnabled: () => void;
};

export const ThemeSettingsContext =
  createContext<ThemeSettingsContextInterface | null>(null);

type Props = {
  children: React.ReactElement;
};

export const ThemeSettingsProvider: React.FC<Props> = ({ children }) => {
  const [isRTLEnabled, setIsRTLEnabled] = useLocalStorage(
    "theme-is-rtl-enabled",
    false
  );
  const { isDarkMode, toggle: toggleDarkModeEnabled } = useDarkMode(false);
  const toggleRTLEnabled = () => {
    setIsRTLEnabled(!isRTLEnabled);
  };

  const toggleFullScreenEnabled = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      await document.documentElement.requestFullscreen();
    }
  };

  return (
    <ThemeSettingsContext.Provider
      value={{
        isDarkMode,
        isRTLEnabled,
        toggleDarkModeEnabled,
        toggleFullScreenEnabled,
        toggleRTLEnabled,
      }}
    >
      {children}
    </ThemeSettingsContext.Provider>
  );
};
