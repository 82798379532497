import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  lighten,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { LoadingButton } from "@mui/lab";
import { useDropzone } from "react-dropzone";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { gql, useMutation } from "@apollo/client";
import { categories } from "../../models/categories";

type FormValues = {
  title: string;
  description: string;
  category: string;
  packages: any[];
  attributes: any[];
};

type Props = {
  initialValues?: Partial<FormValues>;
  onComplete?: () => void;
  onError?: () => void;
};

const s3Client = new S3Client({
  region: "eu-central-1",
  credentials: {
    //todo env
    accessKeyId: "AKIAURNRUFAPE4N7TDMD",
    secretAccessKey: "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",
  },
});

const ATTRIBUTES_LENGTH = 4;
const packages = [
  {
    name: "Basic",
    attributes: new Array(ATTRIBUTES_LENGTH).fill({ value: null }),
  },
  {
    name: "Standard",
    attributes: new Array(ATTRIBUTES_LENGTH).fill({ value: null }),
  },
  {
    name: "Premium",
    attributes: new Array(ATTRIBUTES_LENGTH).fill({ value: null }),
  },
];

export const CreateServiceOfferForm: React.FC<Props> = ({
  initialValues: initialValuesProp = { packages, attributes: [] },
  onComplete,
  onError,
}) => {
  const theme = useTheme();
  const [create] = useMutation(gql`
    mutation CreateServiceOffer($data: CreateServiceOfferDTO!) {
      serviceOfferMutations {
        create(data: $data) {
          id
        }
      }
    }
  `);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState<string | null>(null);
  const [images, setImages] = useState<File[]>([]);
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const url = await uploadFile(acceptedFiles[0]);
    setThumbnailURL(url);
  }, []);

  const onImagesDrop = useCallback(async (acceptedFiles: File[]) => {
    setImages(acceptedFiles);
  }, []);

  const {
    getRootProps: getRootPropsSecond,
    getInputProps: getInputPropsSecond,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: onImagesDrop,
    maxFiles: 5,
    maxSize: 1000000,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop,
    maxFiles: 1,
    maxSize: 1000000,
    disabled: !!thumbnailURL,
  });

  const uploadFile = async (file: File): Promise<string> => {
    setIsThumbnailUploading(true);
    try {
      const key = `${new Date().getTime()}/${file.name}`.replace(
        /[^\.A-z\d.-]/g,
        ""
      );
      const command = new PutObjectCommand({
        Bucket: "rodian-uploads",
        Key: key,
        Body: file,
        ACL: "public-read",
      });
      await s3Client.send(command);
      setIsThumbnailUploading(false);
      return `https://rodian-uploads.s3.eu-central-1.amazonaws.com/${key}`;
    } catch (error) {
      setIsThumbnailUploading(false);
      throw error;
    }
  };

  const onSubmit = async (values: FormValues) => {
    const packages = values.packages.map((item) => {
      item.price = parseFloat(item.price);
      item.deliveryTime = parseInt(item.deliveryTime);
      item.attributes = item.attributes
        .filter(
          (attribute: any, index: number) =>
            !!values.attributes[index] && !!attribute.value
        )
        .map((attribute: any, index: number) => {
          attribute.key = values.attributes[index].value;
          return attribute;
        });
      return item;
    });

    try {
      await create({
        variables: {
          data: {
            title: values.title,
            category: values.category,
            description: values.description,
            photoURL: thumbnailURL,
            packages,
          },
        },
      });
      onComplete?.();
    } catch (error) {
      onError?.();
    }
  };

  const initialValues = useMemo(() => initialValuesProp, []);

  return (
    <Form
      validate={(values) => {
        const errors: any = {
          attributes: [],
          packages: [],
        };

        packages.forEach((item, index) => {
          if (!values.packages[index].price) {
            errors.packages[index] = { price: "Required" };
          }

          if (!values.packages[index].deliveryTime) {
            errors.packages[index] = {
              ...(errors.packages[index] || {}),
              deliveryTime: "Required",
            };
          }
        });

        if (!values.title) {
          errors.title = "Field is required";
        }

        if (!values.category) {
          errors.category = "Field is required";
        }

        return errors;
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting, values }) => (
        <Grid container component="form" spacing={5} onSubmit={handleSubmit}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent sx={{ p: 2 }}>
                <Typography fontWeight="700" sx={{ mb: 1 }}>
                  Cover
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "180px",
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor: lighten(theme.palette.divider, 0.5),
                    overflow: "hidden",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isThumbnailUploading && <CircularProgress />}
                  {thumbnailURL && (
                    <Box
                      component="img"
                      sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                      src={thumbnailURL}
                    />
                  )}
                  {!isThumbnailUploading && !thumbnailURL && (
                    <CameraAltIcon
                      sx={{ color: theme.palette.grey[300], fontSize: "36px" }}
                      fontSize="large"
                      color={isDragActive ? "secondary" : "action"}
                    />
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              About service
            </Typography>
            <Stack spacing={1.5} sx={{ mb: 4 }}>
              <TextField name="category" label="Category" select>
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField name="title" placeholder="Service name" />
              <TextField
                name="description"
                placeholder="Service Description"
                multiline
                rows={5}
              />
              <TableContainer component={Card} sx={{ p: 1.5, mb: 8 }}>
                <Table>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          fontSize="small"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          Package
                        </Typography>
                      </TableCell>
                      {values.packages.map(({ name }, index) => (
                        <TableCell key={name}>
                          <TextField
                            name={`packages[${index}].price`}
                            placeholder="Price"
                            type="number"
                            InputProps={{
                              sx: {
                                fontSize: "24px",
                                fontWeight: "700",
                                mb: 1,
                                "&:before": {
                                  display: "none",
                                },
                                "&:after": {
                                  display: "none",
                                },
                              },
                            }}
                            variant="standard"
                          />
                          <Typography fontSize="small" fontWeight="600">
                            {name}
                          </Typography>
                          <TextField
                            name={`packages[${index}].description`}
                            placeholder="Tariff description"
                            multiline
                            rows={5}
                            InputProps={{
                              sx: {
                                fontSize: "14px",
                                color: "text.secondary",
                                mb: 1,
                                "&:before": {
                                  display: "none",
                                },
                                "&:after": {
                                  display: "none",
                                },
                              },
                            }}
                            variant="standard"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                    {new Array(ATTRIBUTES_LENGTH).fill(null).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ minWidth: "120px" }}>
                          <TextField
                            name={`attributes[${index}].value`}
                            placeholder="Item name"
                            InputProps={{
                              sx: {
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "text.secondary",
                                "&:before": {
                                  display: "none",
                                },
                                "&:after": {
                                  display: "none",
                                },
                              },
                            }}
                            variant="standard"
                          />
                        </TableCell>
                        {packages.map(({ name }: any, packageIndex) => {
                          const isDisabled =
                            index > 0 && !values.attributes?.[index - 1]?.value;
                          return (
                            <TableCell key={name}>
                              <TextField
                                name={`packages[${packageIndex}].attributes[${index}].value`}
                                placeholder={
                                  isDisabled ? "" : "Specify parameter"
                                }
                                disabled={isDisabled}
                                InputProps={{
                                  sx: {
                                    fontWeight: "700",
                                    color: "text.secondary",
                                    fontSize: "14px",
                                    "&:before": {
                                      display: "none",
                                    },
                                    "&:after": {
                                      display: "none",
                                    },
                                  },
                                }}
                                variant="standard"
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell sx={{ minWidth: "150px" }}>
                        <Typography
                          fontSize="small"
                          fontWeight="500"
                          color="text.secondary"
                        >
                          Delivery time
                        </Typography>
                      </TableCell>
                      {packages.map(({ name }: any, index) => (
                        <TableCell key={name}>
                          <TextField
                            name={`packages[${index}].deliveryTime`}
                            placeholder="From (days)"
                            type="number"
                            InputProps={{
                              sx: {
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "text.secondary",
                                "&:before": {
                                  display: "none",
                                },
                                "&:after": {
                                  display: "none",
                                },
                              },
                            }}
                            variant="standard"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <LoadingButton
              loading={submitting}
              type="submit"
              size="large"
              color="primary"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};
