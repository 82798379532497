import { useState, useCallback } from 'react';

export type ToggleHandlers = {
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
};

export const useToggle = (initialState = false): [boolean, ToggleHandlers] => {
  const [isToggled, setIsToggled] = useState(initialState);

  const toggle = useCallback(() => setIsToggled(prev => !prev), []);
  const toggleOn = useCallback(() => setIsToggled(true), []);
  const toggleOff = useCallback(() => setIsToggled(false), []);

  return [
    isToggled,
    {
      toggle,
      toggleOff,
      toggleOn,
    },
  ];
};
