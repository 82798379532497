import {
  Box,
  Container,
  Link as MUILink,
  Stack,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { categories } from "../../models/categories";
import { getMainPageRoute, getServiceOffersRoute } from "../../routes";

export const BaseLayout: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        component="header"
        sx={{
          left: 0,
          [theme.breakpoints.up("sm")]: { position: "sticky", top: 0 },
          [theme.breakpoints.down("sm")]: {
            position: "fixed",
            width: "100%",
          },
          background: theme.palette.common.white,
          zIndex: 1100,
          "& + *": {
            pt: 4,
          },
        }}
      >
        <Container maxWidth="lg">
          <Header
            sx={{
              py: 2,
              [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between",
              },
            }}
          />
        </Container>
        <Box
          component="nav"
          sx={{
            py: 1.5,
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Container>
            <Stack direction="row" alignItems="center" spacing={4}>
              {categories.map((category) => (
                <MUILink
                  component={Link}
                  to={`${
                    getServiceOffersRoute.path
                  }?categories=${encodeURIComponent(category)}`}
                  underline="none"
                  color="text.primary"
                >
                  {category}
                </MUILink>
              ))}
            </Stack>
          </Container>
        </Box>
      </Box>
      <Box component="main">
        <Outlet />
      </Box>
      <Box component="footer" sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </>
  );
};
