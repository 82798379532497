import {
  Box,
  Button,
  ButtonBase,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";
import { AppSearch } from "../AppSearch/AppSearch";
import { AppLogo } from "../AppLogo/AppLogo";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  getBecomeSellerRoute,
  getLoginRoute,
  getMainPageRoute,
  getProfileRoute,
} from "../../routes";
import { useContext } from "react";
import {
  CurrencyContext,
  CurrencyContextInterface,
} from "../../context/CurrencyContext/CurrencyContext";
import { DialogButton } from "../DialogButton/DialogButton";
import { SignUpForm } from "../../forms/SignUpForm/SignUpForm";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";
import { Toggle } from "../Toggle/Toggle";
import { LoginForm } from "../../forms/LoginForm/LoginForm";
import { useSnackbar } from "notistack";
import { MenuButton } from "../MenuButton/MenuButton";

type Props = {
  sx?: StackProps["sx"];
};
export const Header: React.FC<Props> = ({ sx = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthorized, logout } = useContext(
    CognitoAuthContext
  ) as AuthContextInterface;
  const { change, currency, currencySymbol } = useContext(
    CurrencyContext
  ) as CurrencyContextInterface;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={5}
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      <Link to={getMainPageRoute.path}>
        <AppLogo sx={{ height: "20px" }} color={theme.palette.secondary.main} />
      </Link>
      <AppSearch
        sx={{
          width: "500px",
          [theme.breakpoints.down("sm")]: { display: "none" },
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ ml: "auto !important" }}
      >
        <MenuButton
          renderButton={(open) => (
            <ButtonBase disableRipple disableTouchRipple onClick={open}>
              <Typography fontWeight="700" color="text.primary">
                {currency}
              </Typography>
            </ButtonBase>
          )}
          renderMenuItems={(close) =>
            [
              "AUD",
              "BHD",
              "CAD",
              "CLP",
              "CZK",
              "HUF",
              "IDR",
              "JPY",
              "KZT",
              "JOD",
              "KWD",
              "OMR",
              "NZD",
              "NOK",
              "PEN",
              "QAR",
              "SAR",
              "AED",
              "USD",
              "UZS",
              "AZN",
              "EUR",
              "UAH",
              "PLN",
            ].map((currency) => (
              <MenuItem
                key={currency}
                onClick={() => {
                  change(currency);
                  close();
                }}
                value={currency}
              >
                {currency}
              </MenuItem>
            ))
          }
        />
        <NavLink
          to={getBecomeSellerRoute.path}
          style={{ display: "block", textDecoration: "none" }}
        >
          <Typography display="block" fontWeight="700" color="text.primary">
            Become a Seller
          </Typography>
        </NavLink>
        {isAuthorized ? (
          <MenuButton
            renderButton={(open) => (
              <IconButton onClick={open}>
                <AccountCircleIcon />
              </IconButton>
            )}
            renderMenuItems={(close) => [
              <NavLink
                to={getProfileRoute.path}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>Profile</MenuItem>
              </NavLink>,
              <MenuItem onClick={logout}>Log out</MenuItem>,
            ]}
          />
        ) : (
          <>
            <NavLink
              to={getLoginRoute.path}
              style={{ display: "block", textDecoration: "none" }}
            >
              <Typography fontWeight="700" color="text.primary">
                Sign in
              </Typography>
            </NavLink>
            <DialogButton
              renderButton={(open) => (
                <Box color="common.black">
                  <Button
                    onClick={open}
                    variant="outlined"
                    size="small"
                    color="inherit"
                  >
                    Join
                  </Button>
                </Box>
              )}
              maxWidth="sm"
              fullWidth
              renderDialogContent={(close) => (
                <Toggle>
                  {([isSignIn, { toggle }]) =>
                    isSignIn ? (
                      <>
                        <DialogTitle>Sign into your Rodian ID</DialogTitle>
                        <DialogContent>
                          <Typography
                            color="text.secondary"
                            sx={{ mb: 3, fontSize: "14px" }}
                          >
                            If you don't have a Rodian ID, please,{" "}
                            <ButtonBase
                              disableRipple
                              onClick={toggle}
                              sx={{ color: theme.palette.primary.main }}
                            >
                              create one
                            </ButtonBase>
                          </Typography>
                          <LoginForm
                            onError={() => {
                              enqueueSnackbar("Incorrect credentials", {
                                variant: "error",
                              });
                            }}
                            onComplete={() => {
                              close();
                              navigate(getProfileRoute.path);
                              enqueueSnackbar(
                                "Successfully logged in to your ID",
                                {
                                  variant: "success",
                                }
                              );
                            }}
                          />
                        </DialogContent>
                      </>
                    ) : (
                      <>
                        <DialogTitle>Create an account Rodian ID</DialogTitle>
                        <DialogContent>
                          <Typography
                            color="text.secondary"
                            sx={{ mb: 3, fontSize: "14px" }}
                          >
                            If you have an account,{" "}
                            <ButtonBase
                              disableRipple
                              onClick={toggle}
                              sx={{ color: theme.palette.primary.main }}
                            >
                              log in
                            </ButtonBase>
                          </Typography>
                          <SignUpForm
                            onError={() => {
                              enqueueSnackbar(
                                "Something went wrong. We will fix it soon",
                                {
                                  variant: "error",
                                }
                              );
                            }}
                            onComplete={() => {
                              close();
                              navigate(getProfileRoute.path);
                              enqueueSnackbar(
                                "Successfully registered on the platform",
                                {
                                  variant: "success",
                                }
                              );
                            }}
                          />
                        </DialogContent>
                      </>
                    )
                  }
                </Toggle>
              )}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
