import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";

type Props = {
  children: React.ReactElement;
  redirectTo: string;
};
export const GuestGuard: React.FC<Props> = ({ children, redirectTo }) => {
  const { isAuthorized } = useContext(
    CognitoAuthContext
  ) as AuthContextInterface;

  const params = window.location.search;
  const urlSearchParams = new URLSearchParams(params);
  const redirectPath = urlSearchParams.get("redirect");

  if (isAuthorized) {
    return <Navigate to={redirectPath || redirectTo} />;
  }

  return <>{children}</>;
};
