import * as React from 'react';
import { useState } from 'react';
import { Menu, MenuProps } from '@mui/material';

export interface Props extends Omit<MenuProps, 'open'> {
  renderButton: (
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => React.ReactNode;
  renderMenuItems: (handleClose: () => void) => any;
}

export const MenuButton: React.FC<Props> = ({
  renderButton,
  renderMenuItems,
  ...menuProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {renderButton(handleOpen)}
      <Menu
        {...menuProps}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {renderMenuItems(handleClose)}
      </Menu>
    </>
  );
};
