import ReactDOM from "react-dom/client";
import "./utils/i18n/i18n";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import { Suspense, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ErrorBoundary } from "react-error-boundary";
import { LocalizationProvider as MUIPickerLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { apolloClient } from "./store/apolloClient";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import { getLightTheme, getDarkTheme } from "./theme";
import { ThemeSettingsProvider } from "./context/ThemeSettingsContext";
import { PageLoader } from "./components/PageLoader/PageLoader";
import { Routes } from "./routes/Routes";
import { useThemeSettingsContext } from "./hooks/useThemeSettingsContext";
import { CurrencyProvider } from "./context/CurrencyContext/CurrencyContext";
import {
  CognitoAuthContext,
  CognitoAuthProvider,
} from "./context/AuthContext/CognitoAuthContext";
import { cacheLTR, cacheRTL } from "./theme/cache";

const App = () => {
  const { isDarkMode, isRTLEnabled } = useThemeSettingsContext();
  const direction = isRTLEnabled ? "rtl" : "ltr";
  useEffect(() => {
    document.dir = direction;
  }, [isRTLEnabled]);

  return (
    <CacheProvider value={isRTLEnabled ? cacheRTL : cacheLTR}>
      <ThemeProvider
        theme={isDarkMode ? getLightTheme(direction) : getLightTheme(direction)}
      >
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          maxSnack={3}
        >
          <CurrencyProvider>
            <BrowserRouter>
              <Suspense fallback={<PageLoader fullScreen />}>
                <CognitoAuthProvider>
                  <CognitoAuthContext.Consumer>
                    {({ isLoading }: any) =>
                      isLoading ? <PageLoader fullScreen /> : <Routes />
                    }
                  </CognitoAuthContext.Consumer>
                </CognitoAuthProvider>
              </Suspense>
            </BrowserRouter>
          </CurrencyProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <MUIPickerLocalizationProvider dateAdapter={AdapterLuxon}>
      <ApolloProvider client={apolloClient}>
        <ThemeSettingsProvider>
          <App />
        </ThemeSettingsProvider>
      </ApolloProvider>
    </MUIPickerLocalizationProvider>
  </ErrorBoundary>
);
