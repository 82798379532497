import { Form } from "react-final-form";
import { Stack } from "@mui/material";
import { Checkboxes, TextField } from "mui-rff";
import { LoadingButton } from "@mui/lab";
import { useContext, useState } from "react";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";

type FormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type Props = {
  initialValues?: Partial<FormValues>;
  onComplete?: () => void;
  onError?: () => void;
};

export const LoginForm: React.FC<Props> = ({
  initialValues,
  onComplete,
  onError,
}) => {
  const { login } = useContext(CognitoAuthContext) as AuthContextInterface;

  const onSubmit = async (values: FormValues) => {
    try {
      await login(values.email, values.password);
      onComplete?.();
    } catch (e) {
      onError?.();
    }
  };

  return (
    <Form
      validate={(values) => {
        const errors: any = {};

        if (!values.email) {
          errors.email = "Field is required";
        }

        if (!values.password) {
          errors.password = "Field is required";
        }
        return errors;
      }}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting }) => (
        <Stack component="form" onSubmit={handleSubmit} spacing={2}>
          <TextField name="email" placeholder="Email" type="email" />
          <TextField type="password" name="password" placeholder="Password" />
          <Checkboxes
            name="rememberMe"
            data={{
              label: "Remember me",
              value: true,
            }}
          />
          <LoadingButton
            type="submit"
            loading={submitting}
            variant="contained"
            fullWidth
            size="large"
          >
            Sign in
          </LoadingButton>
        </Stack>
      )}
    </Form>
  );
};
