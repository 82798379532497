import { lighten } from "@mui/material";

export const lightPalette = {
  background: {
    default: "#ffffff",
    paper: "#F0F4F4",
  },
  divider: "#E2E9E9",
  error: {
    main: "#E95F59",
    light: "#FCE6E6",
    contrastText: "#E82E2E",
  },
  primary: {
    dark: "#1aa865",
    light: "#a1ffcf",
    main: "#1DBF73",
  },
  warning: {
    dark: "#654201",
    main: "#FEDB9A",
    light: "#FFF6E6",
  },
  secondary: {
    dark: "#415758",
    light: lighten("#567476", 0.3),
    main: "#567476",
  },
  success: {
    main: "#5DCB98",
  },
  text: {
    primary: "#404145",
    secondary: "#74767E",
  },
};
