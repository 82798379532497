import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

export type FileUploadReturn = {
  isLoading: boolean;
  isError: boolean;
  upload: (file: File) => Promise<any>;
};
export const useFileUpload = (): FileUploadReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [createFileObject] = useMutation(gql`
    mutation CreateFileObject($data: CreateFileObjectDTO!) {
      fileObjectMutations {
        create(data: $data) {
          id
          name
          uploadURL
          downloadURL
          uploadedAt
          createdAt
        }
      }
    }
  `);

  const upload = async (file: File): Promise<any> => {
    const fileObject = await handleCreateFile(file);
    await handleUploadFile(fileObject.uploadURL, file);
    return fileObject;
  };

  const handleCreateFile = async (file: File): Promise<any> => {
    setIsLoading(true);
    setIsError(false);
    try {
      const {
        data: {
          fileObjectMutations: { create: fileObject },
        },
      } = await createFileObject({
        variables: {
          data: {
            contentType: file.type,
          },
        },
      });
      setIsLoading(false);
      return fileObject;
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleUploadFile = async (url: string, file: File): Promise<void> => {
    try {
      setIsLoading(true);
      setIsError(false);
      await fetch(url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    upload,
  };
};
