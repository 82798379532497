import { Form } from "react-final-form";
import { Box, inputBaseClasses, useTheme } from "@mui/material";
import { TextField } from "mui-rff";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

type FormValues = {
  email: string;
};

type Props = {
  initialValues?: Partial<FormValues>;
  onComplete?: () => void;
};

export const NewsletterForm: React.FC<Props> = ({
  initialValues,
  onComplete,
}) => {
  const theme = useTheme();
  const onSubmit = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    onComplete?.();
  };

  return (
    <Form
      validate={(values) => {
        const errors: any = {};
        if (!values.email) {
          errors.email = "Field is required";
        }

        return errors;
      }}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitSucceeded, submitting }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            name="email"
            placeholder="Your email"
            sx={{
              [`& .${inputBaseClasses.root}`]: {
                boxShadow: "0px 4px 32px rgba(27, 52, 79, 0.16)",
                background: theme.palette.common.white,
                borderRadius: "50px",
                p: 0,
                pl: 1,
              },
            }}
            InputProps={{
              endAdornment: (
                <LoadingButton
                  sx={{ minWidth: "120px", m: "5px" }}
                  size="large"
                  variant="contained"
                  type="submit"
                  loading={submitting}
                  disabled={submitSucceeded}
                  startIcon={submitSucceeded && <CheckCircleIcon />}
                >
                  {submitSucceeded ? "Subscribed" : "Subscribe"}
                </LoadingButton>
              ),
            }}
          />
        </Box>
      )}
    </Form>
  );
};
