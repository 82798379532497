import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../../translations/en.json';
import ru from '../../translations/ru.json';

const resources = {
  en,
  ru,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    defaultNS: 'general',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    resources,
  });
