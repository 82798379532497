import {
  Autocomplete,
  Box,
  CircularProgress,
  darken,
  InputAdornment,
  lighten,
  StackProps,
  TextField,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { gql, useQuery } from "@apollo/client";
import {
  CurrencyContext,
  CurrencyContextInterface,
} from "../../context/CurrencyContext/CurrencyContext";
import { useNavigate } from "react-router-dom";
import { getServiceOfferRoute } from "../../routes";

type Props = {
  sx?: StackProps["sx"];
};
export const AppSearch: React.FC<Props> = ({ sx = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState("");
  const debouncedValue = useDebounce(term, 500);

  const query = useQuery(
    gql`
      query ServiceOffersSearch(
        $sortBy: String
        $sortDirection: SortDirection
        $take: Float
        $title: String
      ) {
        serviceOffersPaged(
          sortBy: $sortBy
          sortDirection: $sortDirection
          take: $take
          title: $title
        ) {
          serviceOffers {
            id
            title
          }
          count
        }
      }
    `,
    {
      variables: {
        name: debouncedValue,
        take: 10,
        sortBy: "rating",
        sortDirection: "DESC",
      },
      skip: !debouncedValue,
    }
  );

  const options =
    query.data?.serviceOffersPaged?.serviceOffers.map((serviceOffer: any) => ({
      id: serviceOffer.id,
      name: serviceOffer.title,
    })) || [];

  return (
    <Autocomplete
      sx={{ width: "auto" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option: any, value: any) =>
        option.name === value.name
      }
      onInputChange={(event, value, reason) => {
        setTerm(value);
      }}
      onChange={(event, value, reason, details) => {
        setTerm("");
        navigate(getServiceOfferRoute({ serviceOfferId: value.id }));
      }}
      inputValue={term}
      getOptionLabel={(option: any) => option.name}
      options={options}
      loading={query.loading}
      fullWidth
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            ...sx,
            ".MuiInputBase-root": {
              p: "3px !important",
              pr: "4px !important",
              borderRadius: "50px",
            },
            ".MuiInputBase-input": {
              py: `${theme.spacing(1)} !important`,
              px: `${theme.spacing(2)} !important`,
            },
          }}
          fullWidth
          size="small"
          placeholder={`Try "building mobile app"`}
          color="primary"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  mr: 0,
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "38px",
                  minHeight: "38px",
                  background: theme.palette.secondary.main,
                  borderRadius: "50px",
                  color: theme.palette.common.white,
                }}
              >
                {query.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
