import { createContext } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useApolloClient } from "@apollo/client";

export interface CurrencyContextInterface {
  currency: string;
  currencySymbol: string;
  change: (currency: string) => void;
}

export const CurrencyContext = createContext<CurrencyContextInterface | null>(
  null
);

const CURRENCY_TO_SYMBOL_MAP: Record<string, string> = {
  RUB: "₽",
  EUR: "€",
  USD: "$",
  TRY: "₺",
};

type Props = {
  children: React.ReactElement;
};
export const CurrencyProvider: React.FC<Props> = ({ children }) => {
  const client = useApolloClient();
  const [currency, setCurrency] = useLocalStorage<string>("currency", "EUR");
  const change = (value: string) => {
    setCurrency(value);
    client.refetchQueries({
      //todo!
      include: [
        "ServiceOffer",
        "ServiceOffersPaged",
        "AuthorServiceOffersPaged",
      ],
    });
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        change,
        currencySymbol: CURRENCY_TO_SYMBOL_MAP[currency],
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
