import { createTheme } from '@mui/material';
import { baseMuiTheme } from './baseMuiTheme';
import { darkPalette, lightPalette } from './palettes';

export const getLightTheme = (direction: string) =>
  createTheme(baseMuiTheme, {
    direction,
    palette: {
      mode: 'light',
      ...lightPalette,
    },
  });

export const getDarkTheme = (direction: string) =>
  createTheme(baseMuiTheme, {
    direction,
    palette: {
      mode: 'dark',
      ...darkPalette,
    },
  });
