import { createContext, useEffect, useState } from "react";
const {
  CognitoIdentityProviderClient,
  SignUpCommand,
} = require("@aws-sdk/client-cognito-identity-provider");
import { AuthContextInterface, UserInterface } from "./AuthContext";
import {
  ConfirmSignUpCommand,
  GetUserCommand,
  ResendConfirmationCodeCommand,
  InitiateAuthCommand,
  UpdateUserAttributesCommand,
  VerifyUserAttributeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { useLocalStorage } from "usehooks-ts";

// todo env
const clientId = "709dcq5e9cvo0eo9m3geskl7k5";
const cognitoIdentityServiceProvider = new CognitoIdentityProviderClient({
  region: "eu-central-1",
});

export const CognitoAuthContext = createContext<AuthContextInterface | null>(
  null
);

type Props = {
  children: React.ReactElement;
};

export const CognitoAuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useLocalStorage("auth-token", "");
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] =
    useState<AuthContextInterface["currentUser"]>(null);

  useEffect(() => {
    const fetch = async () => {
      if (!!token) {
        // todo check if token not expired
        try {
          const user = await getUser(token);
          setCurrentUser(user);
        } catch (error) {
          console.error(error);
        }
      }
      setIsLoading(false);
    };

    fetch();
  }, []);

  const getUser = async (token: string): Promise<UserInterface> => {
    const command = new GetUserCommand({
      AccessToken: token,
    });
    const { UserAttributes, ...rest } =
      await cognitoIdentityServiceProvider.send(command);

    console.log(rest);

    return {
      id: UserAttributes?.find(({ Name }: any) => Name === "sub")
        ?.Value as string,
      email: UserAttributes?.find(({ Name }: any) => Name === "email")
        ?.Value as string,
      firstName: UserAttributes?.find(
        ({ Name }: any) => Name === "custom:firstName"
      )?.Value as string,
      lastName: UserAttributes?.find(
        ({ Name }: any) => Name === "custom:lastName"
      )?.Value as string,
      avatarURL: UserAttributes?.find(
        ({ Name }: any) => Name === "custom:avatarURL"
      )?.Value as string,
      phone: UserAttributes?.find(({ Name }: any) => Name === "phone_number")
        ?.Value as string,
      jobTitle: UserAttributes?.find(
        ({ Name }: any) => Name === "custom:jobTitle"
      )?.Value as string,
    };
  };

  const refetchUser = async () => {
    if (!currentUser) {
      throw new Error("User is not authorized");
    }

    const user = await getUser(token);
    setCurrentUser(user);
  };

  const login = async (email: string, password: string) => {
    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId: clientId,
    });
    const data = await cognitoIdentityServiceProvider.send(command);
    const token = data.AuthenticationResult?.AccessToken;
    setToken(token);
    const user = await getUser(token);
    setCurrentUser(user);
  };

  const resendConfirmation = async (email: string) => {
    const command = new ResendConfirmationCodeCommand({
      Username: email,
      ClientId: clientId,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const signUp = async (
    email: string,
    password: string,
    attributes: { name: string; value: string }[] = []
  ) => {
    const command = new SignUpCommand({
      ClientId: clientId,
      Username: email,
      Password: password,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const changeAttributes = async (
    attributes: { name: string; value: string }[] = []
  ) => {
    if (!currentUser) {
      throw new Error("User is not authorized");
    }
    const command = new UpdateUserAttributesCommand({
      AccessToken: token,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const confirm = async (email: string, code: string) => {
    const command = new ConfirmSignUpCommand({
      ClientId: clientId,
      Username: email,
      ConfirmationCode: code,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const confirmAttributeChange = async (attribute: string, code: string) => {
    if (!currentUser) {
      throw new Error("User is not authorized");
    }
    const command = new VerifyUserAttributeCommand({
      AccessToken: token,
      AttributeName: attribute,
      Code: code,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const logout = async () => {
    setToken("");
    setCurrentUser(null);
  };

  return (
    <CognitoAuthContext.Provider
      value={{
        isAuthorized: !!currentUser,
        changeAttributes,
        confirmAttributeChange,
        isLoading,
        login,
        logout,
        signUp,
        confirm,
        currentUser,
        resendConfirmation,
        refetchUser,
      }}
    >
      {children}
    </CognitoAuthContext.Provider>
  );
};
