import { useCallback, useState } from "react";
import * as React from "react";
import { Dialog, DialogProps } from "@mui/material";

export interface Props extends Omit<DialogProps, "children" | "open"> {
  renderButton: (handleOpen: () => void) => React.ReactNode;
  renderDialogContent: (handleClose: () => void) => React.ReactNode;
}

export const DialogButton: React.FC<Props> = React.forwardRef(
  ({ renderButton, renderDialogContent, ...dialogProps }: Props, ref) => {
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const openDialog = useCallback(() => setIsDialogOpened(true), []);
    const closeDialog = useCallback(() => setIsDialogOpened(false), []);

    return (
      <>
        {renderButton(openDialog)}
        <Dialog
          {...dialogProps}
          ref={ref}
          open={isDialogOpened}
          onClose={closeDialog}
        >
          {renderDialogContent(closeDialog)}
        </Dialog>
      </>
    );
  }
);
